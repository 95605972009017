export const crcValidate = (byteArray) => {
    
    let check = false;
    let crc = 0;
    let checksum = getCRC(byteArray);

    crc = parseInt(byteArray[byteArray.length + -2]);
    crc <<= 8;
    crc |= parseInt(byteArray[byteArray.length + -1]);

    check = checksum === crc ? true : false;
    return check;

}


export const crcGenerate = (byteArray) => {
  
    let buffer = byteArray;
    let crc  = getCRC(byteArray);

    buffer[15] = crc >> 8;
    buffer[16] = crc & 0x00FF;

    return buffer;
}

const getCRC = (byteArray) => {
    
    let checksum = 0xFFFF;
    for (let index = 0; index < (byteArray.length - 2); index++) {
        const myByte = byteArray[index];
        checksum ^= parseInt(myByte);
        
        for (let a = 0; a < 8; a++) {
            if ((checksum & 0x0001) === 0) {
                checksum >>= 1;
            }               
            else  {
                checksum = (checksum >> 1) ^ 0xC001;
            }            
        }
    }
    return checksum;

}

export const tempReader = (temp) => {
    
    const tab = [
        1835, 1493, 1151, 969, 847, 754, 678, 615,
        560, 511, 466, 425, 386, 350, 316, 282, 250,
        218, 187, 156, 125, 93, 61, 28, -6, -43,
        -83, -126, -176, -235, -311, -428, -545
    ];     
      
    const NTC_ADC2Temperature = (value) => {      
    
        const p1 = tab[ (value >> 7)  ];
        const p2 = tab[ (value >> 7)+1];
        
        return p1 - ( (p1-p2) * (value & 0x007F) ) / 128;
    }
      
    const temper = NTC_ADC2Temperature(temp);
      
    return temper / 10;

}