import React from 'react';
import * as version from './metadata.json';

import Communicat from './Communicat';
import Preview from "./containers/Preview";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import logo from './logo.svg';

function App() {


  return (
    <div className="App">
      
        <Router>
          <Switch>
            <Route exact path="/">
              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                      
              </header>
                <div className="App-body">
              <Communicat />
              </div>
            </Route>
            <Route path="/preview">
              <Preview/>
            </Route>         
          </Switch>
        </Router>
        
     
      
      <div className="version">V{version.build}</div>
    </div>
  );
}

export default App;
