import React from 'react';
import {crcGenerate, crcValidate, tempReader} from './shared/utility';
import SamplePattern from "./shared/pattern";
class Communicat extends React.Component {

    device;

    rawData = [];
    samplesArray = [];

    // windowTime: 40,
    //         sampleTime: 20,
    //         standByTime: 30,
    constructor(props) {
        super(props);
        this.state = { 
            isReceiving : false, 
            status: "",
            windowTime: 7,//20,
            sampleTime: 20, //40,
            standByTime: 1,
            cicles : 5,
            sampleId: "",
            addLed: false,
            addTemp: true,
            addHomo: true,
            serial: '',
            prevs: []
        };

        
        this.startHandler = this.startHandler.bind(this);
        
        this.crcGenerate = crcGenerate;
        this.tempReader = tempReader;
        
    }

    componentDidMount() {
        this.mirrorLocalToState();
    }

    mirrorLocalToState(){
        if (localStorage.getItem("coletas") !== null) {                
            try{
                let coletas = JSON.parse(localStorage.getItem("coletas"));
                this.setState({prevs: coletas.map(coleta=>{
                    coleta.samples = []; 
                    return coleta;
                })});

            } catch(err){
                console.log(err);
            }
        } 

    }

    async loadDevice() {

        // await navigator.hid.getDevices({}, (devices)=>{
        //     debugger
        //     console.log(devices)
        // })
        
        // .then(devices=>{
        //     debugger
        //     console.log(devices)
        // })

        await navigator.hid.requestDevice({ filters: [{ vendorId: 0x0483 }] })
        .then(selectedDevice => {               
            if(Array.isArray(selectedDevice)){
                this.device = selectedDevice[0];
            } else {
                this.device = selectedDevice;
            }

            console.log(this.device)
            
        })
    }

    async colectHandler () {

        if (!this.device.opened){
            await this.device.open()
        }       
        // await this.reportAndRecieveConfig()
        // return new Promise(resolve => {
        //     
        //     resolve()
        // })
        await this.reportAndRecieveConfig();

        

    }

    async startHandler() {
        this.setState({
            status : "Verificando conexão com o aparelho...",

        })

        try {
            // verifica se o device já foi carregado
            if (!this.device) {
                // caso não carregado, carregar                
                await this.loadDevice();
                for (let index = 0; index < this.state.cicles; index++) {
                    console.log(`ciclo: ${index+1}`)
                    await this.colectHandler()
                    console.log("acabo?")           
                }
                
            } else {
                if (!this.device.opened) { 
                    for (let index = 0; index < this.state.cicles; index++) {
                        console.log(`ciclo: ${index+1}`)
                        await this.colectHandler()
                        console.log("acabo?")                     
                    }
                }
            }
            
        } catch (error) {
            console.log("Erro ao iniciar device: " , error);
            this.setState({
                status : "Não foi possivel acessar o equipamento!"
            })
            return;

        }
    }


    convertData(rawData) {

        let samples = [];

      
    
        const getLedStatus = (byte)=>  {
    
            const fullByte = (byte >>> 0).toString(2);
            var pad = "00000"
            var bin = pad.substring(0, pad.length - fullByte.length) + fullByte

            let ledStatus = [];
            for (var i = 0; i < 5; i++) {
                //ledStatus.push( fullByte.charAt(i) === '1' ? true : false );
                ledStatus.push( bin.charAt(i) === '1' ? '1' : '0' );
            }
    
            return ledStatus;
        }
    
        const getSequencial = (payload) => {              
            let seq = payload[0];
            seq <<= 8;
            seq |= payload[1];
            return seq;
        }
    
        const getOPTO = (payload)=>  {
            let opto = payload[3];
            opto = opto << 8;
            opto |= payload[4];
    
            return opto;
        }
        
        const getNTC = (payload)=>  {
    
            let ntc = payload[5];
            ntc = ntc << 8;
            ntc |= payload[6];    
            return ntc.toPrecision(5);
        } 

        console.log("O indice começa com: " + getSequencial(this.rawData[0].slice(4, 14)));
        
        let sequenceCheck = true;
        for (let count = 0; count < this.rawData.length; count++) {            

            const rowCount = this.rawData[count];

            if (!crcValidate(rowCount)){
                console.log("ERRO DE CRC");
            }
            
            const sample = {      
                'seq'     : getSequencial(rowCount.slice(4, 14)),                       
                'ledStatus'   : getLedStatus(rowCount[6]),
                'opto'    : getOPTO(rowCount.slice(4, 14)),
                'ntc'     : tempReader(getNTC(rowCount.slice(4, 14))),                                  
                
            }

            if (sample.seq !== count) {
                console.log(sample.seq + " <==> " +  count);
                sequenceCheck = false;
            }
            samples.push(sample);
            
        }
        this.rawData = [];
        return {samples, sequenceCheck};
    }


    
    

    reportAndRecieveConfig () {
        return new Promise(resolve => {

            const t0 = performance.now();
            let t1;
        

            const timeOutError = ()=> {
                this.setState({
                    status : "Erro, equipamento deixou de responder por 5 segundos. "         
                })
            }
            let timeoutHandle = window.setTimeout(timeOutError, 4000 );

            this.setState({
                status : "Iniciando leitura... "
            });


            const storageSample = newSample=>{

                
                if (localStorage.getItem("coletas") === null) {                
                    localStorage.setItem("coletas", JSON.stringify([newSample]));
                } else {

                    try{
                        let coletas = JSON.parse(localStorage.getItem("coletas"));
                        coletas = [newSample, ...coletas]
                        localStorage.setItem("coletas", JSON.stringify(coletas));
        
                    } catch(err){
                        console.log(err);
                    }
                }            
            }
            
            
            const afterAll = ()=> {


                this.device.close();
                this.device.removeEventListener('inputreport', deviceEventHandler, false);

                

                let {samples, sequenceCheck} = this.convertData(this.rawData);
                console.log("cicloS:  " + this.state.cicles)

                if (this.state.cicles > 1) {
                    console.log("ciclo é maior que 1")
                    this.samplesArray = [...this.samplesArray, samples];
                    console.log("length array: "+ this.samplesArray.length)
                    
                    if(parseInt(this.state.cicles) === this.samplesArray.length) {
                        console.log("Finalzado ciclos")
                        
                        let mergedArray = [];
                        this.samplesArray.forEach(array => {
                            mergedArray = mergedArray.concat(array)
                        });
                        samples = mergedArray;
                        this.samplesArray = [];
                    } else {
                        resolve();
                        return;
                    }
                    
                }



                let DP = [];
                // calculo de variança
                if(samples.length === SamplePattern.length) {
                    console.log("aplicando calcculo de variança");

                    
                    for (let point = 0; point < SamplePattern.length; point++) {                
                        const pattern = SamplePattern[point];                
                        const dif = pattern - samples[point].opto;
                        DP.push(Math.sqrt((Math.pow(dif, 2))));                
                    }
                }



                
                const nowDate = new Date();

            
                const sampleDate = nowDate.toLocaleDateString() + " - " + nowDate.toLocaleTimeString();

                const sampleObj = {
                    'date': sampleDate,
                    'ciclos': this.state.cicles,
                    'sampleId' : this.state.sampleId,
                    'time': Math.floor((t1 - t0)/1000),
                    'check': sequenceCheck,
                    'counts': samples.length,
                    'avTemp' : "",                
                    'DP': DP,
                    'sel' : true
                }
                this.setState({
                    status : "- Processo finalizado! total de counts " + samples.length ,
                    prevs: [  ...[sampleObj], ...this.state.prevs, ]           
                });

                sampleObj["samples"] = samples;

                storageSample(sampleObj);
                resolve();
                
            }

                
            let indexR = 0

            const deviceEventHandler = (event) => {

                let array = new Uint8Array(event.data.buffer); 
                

                if (array[1] === 129) {
                    this.rawData.push(array);
                    indexR++;

                    if (indexR % 20 === 0) {                    
                        this.setState({
                            status : "Iniciando leitura... ("+ indexR + " pontos recebidos )"
                        });
                    }                
                    window.clearTimeout(timeoutHandle);
                    timeoutHandle = window.setTimeout(timeOutError, 5000 );

                } else if (array[1] === 130) {

                    t1 = performance.now();
                    console.log("tempo de coleta " + (t1 - t0) + " milliseconds.");
                    window.clearTimeout(timeoutHandle);
                    afterAll();                
                }

            }

            this.device.addEventListener('inputreport', deviceEventHandler, false);

       
            this.device.sendReport(0x00, this.encodeRequest("conf", this.state.windowTime, this.state.sampleTime, this.state.standByTime)).then(() => {
                window.clearTimeout(timeoutHandle);
                timeoutHandle = window.setTimeout(timeOutError, this.state.standByTime * 1000 );

                this.setState({
                    status : "Aguardando "+ this.state.standByTime + " segundos..."
                });
                
                console.log('Sent config packet');
            });         
            
            
            
        
        });

    }

    

    encodeRequest(cmdType, windowTime,  sampleTime, standByTime) {

        // START | CMD | SUBCMD | SIZE | PAYLOAD [...] | STOP | CRC


        switch (cmdType) {
            case "conf":               
                const cmd = new Uint8Array([
                    0x55, 0x00, 0x00, 0x0A,
                    windowTime, sampleTime, standByTime,
                    0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0xAA, 0x00, 0x00      
                ]);
                
                const buffer = crcGenerate(cmd);
                console.log(buffer);
                return  buffer
                
        
            default:
                break;
        }


    }
    handleStandByChange = (event) =>{ 
        this.setState({
            standByTime:  event.target.value
        });
    }
    handleIdChange = (event) =>{ 
        this.setState({
            sampleId:  event.target.value
        });
    }

    handleCiclesChange = (event) =>{ 
        this.setState({
            cicles:  event.target.value
        });
    }

    handleWindowChange = (event) =>{ 
        this.setState({
            windowTime:  event.target.value
        });
    }

    handleSampleChange = (event) =>{ 
        this.setState({
            sampleTime:  event.target.value
        });
    }

    clearStateHandler = () =>{

        var conf = window.confirm("CUIDADO!!!\nDeseja mesmo limpar os dados de coleta?");
        if (conf === true) {
            localStorage.setItem('coletas', JSON.stringify([]));
            this.setState({
                prevs: []
            })
        } 

        
    }

    exportHandlerNormalized = () => {

        
        let csvContent = "data:text/csv;charset=utf-8,";
        //let selectedFilter = this.state.prevs.map(el=>el.sel && el);

        let biggerCount = 0;
        let filteredSampes = [];
        if (localStorage.getItem("coletas") !== null) {                
            try{
                const coletas = JSON.parse(localStorage.getItem("coletas"));
                this.state.prevs.forEach(element => {
                    
                    if(element.sel) {
                        const coleta = coletas.find(el => el.date === element.date);
                        if(biggerCount < coleta.counts) {
                            biggerCount  = coleta.counts
                        }
                        filteredSampes.push(coleta)
                    }
                });
            } catch(err){
                console.log(err);
            }
        } 


        for (let samp = 0; samp < filteredSampes.length; samp++) {

            debugger
            csvContent += filteredSampes[samp].sampleId + ","

            let opts = [];
            let ntcs = [];

            let leds = [[],[],[],[],[]];

            for (let count = 0; count < biggerCount; count++) {  
                if (typeof filteredSampes[samp].samples[count] !== "undefined"){

                    switch (filteredSampes[samp].samples[count].ledStatus.toString()) {
                        case "1,0,0,0,0":
                            leds[4].push(filteredSampes[samp].samples[count].opto)
                            break;
                        case "0,1,0,0,0":
                            leds[3].push(filteredSampes[samp].samples[count].opto)
                            break;
                        case "0,0,1,0,0":
                            leds[2].push(filteredSampes[samp].samples[count].opto)
                            break;
                        case "0,0,0,1,0":
                            leds[1].push(filteredSampes[samp].samples[count].opto)
                            break;
                        case "0,0,0,0,1":
                            leds[0].push(filteredSampes[samp].samples[count].opto)
                            break;
                    
                        default:
                            break;
                    }

                    
                } else {
                    opts.push(0);
                    ntcs.push(0);
                }
                
            }

            let led1 = Math.floor(leds[0].reduce((a,b)=>a+b)/leds[0].length);
            let led2 = Math.floor(leds[1].reduce((a,b)=>a+b)/leds[1].length);
            let led3 = Math.floor(leds[2].reduce((a,b)=>a+b)/leds[2].length);
            let led4 = Math.floor(leds[3].reduce((a,b)=>a+b)/leds[3].length);
            let led5 = Math.floor(leds[4].reduce((a,b)=>a+b)/leds[4].length);

            let ledsMedia = [led1, led2, led3, led4, led5]

            let divisor = led1;
            let led1Norm = Math.floor((led1 / divisor) * 1000);
            let led2Norm = Math.floor((led2 / divisor) * 1000);
            let led3Norm = Math.floor((led3 / divisor) * 1000);
            let led4Norm = Math.floor((led4 / divisor) * 1000);
            let led5Norm = Math.floor((led5 / divisor) * 1000);

            let ledsNorm = [ led1Norm, led2Norm, led3Norm, led4Norm, led5Norm];
                            
            csvContent += ledsMedia.toString() + "," + ledsNorm.toString() + "\n";               
            
        }
             

        let encodedUri = encodeURI(csvContent);
        
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();  
      

    }

    filterSamples = () => {

        let biggerCount = 0;
        let filteredSampes = [];

        if (localStorage.getItem("coletas") !== null) {                
            try{
                const coletas = JSON.parse(localStorage.getItem("coletas"));
                this.state.prevs.forEach(element => {
                    
                    if(element.sel) {
                        const coleta = coletas.find(el => el.date === element.date);
                        if(biggerCount < coleta.counts) {
                            biggerCount  = coleta.counts
                        }
                        filteredSampes.push(coleta)
                    }
                });
            } catch(err){
                console.log(err);
            }
        } 

        return [filteredSampes, biggerCount];

    }

    exportJsonHandler = () => {

        const filename = 'data.json';
        const jsonStr = JSON.stringify(this.filterSamples()[0]);

        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
        element.setAttribute('download', filename);
        element.click(); 

    }
    exportHandler = () => {

        let csvContent = "data:text/csv;charset=utf-8,";
        //let selectedFilter = this.state.prevs.map(el=>el.sel && el);

        const filter = this.filterSamples();
        const filteredSampes = filter[0];
        const biggerCount = filter[1]

        for (let samp = 0; samp < filteredSampes.length; samp++) {

            
            csvContent += filteredSampes[samp].sampleId + ","

            let opts = [];
            let optsHomo = [];
            let ntcs = [];
            let leds = []

            let homoList = [52,87,139,174,195,261,313,348,383,390,418,470,505,557,585,627,696,714,766,780,801,836,888,923,975]

            const ciclos = filteredSampes[samp].ciclos
            
            if (ciclos > 1 ) {
                
                for (let ciclo = 1; ciclo < ciclos; ciclo++) {
                
                    let array = []
                    for (let index = 0; index < homoList.length; index++) {
                        const element = homoList[index];
                        const soma = (filteredSampes[samp].samples.length/ciclos) * ciclo;
                        array.push(element+soma);
                    }      
                    
                    homoList = [...homoList, ...array]
                    
                }
                debugger

            }

            for (let count = 1; count < biggerCount; count++) {  
                
                if (typeof filteredSampes[samp].samples[count] !== "undefined"){
                    opts.push(filteredSampes[samp].samples[count].opto);
                
                    if (!homoList.includes(count + 1)){           
                        optsHomo.push(filteredSampes[samp].samples[count].opto);
                    }

                    ntcs.push(filteredSampes[samp].samples[count].ntc);                    
                    leds.push(filteredSampes[samp].samples[count].ledStatus.join(''))
                } else {
                    opts.push(0);
                    ntcs.push(0);
                    leds.push([])
                }

                
                
            }
                            
                           
            if (this.state.addLed) {
                csvContent += leds.toString() + "," ;
            }

            debugger
            if (this.state.addHomo) {
                csvContent += optsHomo.toString();
            } else {
                csvContent += opts.toString();
            }

            if (this.state.addTemp) {
                csvContent += "," + ntcs.toString();
            }
            
            //csvContent += leds.toString() + "," + opts.toString() + "," + ntcs.toString() + "\n";
            csvContent +=  "\n";

        }
             
            
        
        // para exportação em colunas
        // for (let count = 0; count < biggerCount; count++) {

        //     if (count === 0) {
        //         for (let header = 0; header < filteredSampes.length; header++) {
        //             csvContent += filteredSampes[header].sampleId + "-OPTO," + filteredSampes[header].sampleId + "-NTC," ;
        //         }   
        //         csvContent.slice(0, -1);  
        //         csvContent += "\n";           
        //     }

        //     for (let samp = 0; samp < filteredSampes.length; samp++) {
        //         debugger                
        //         csvContent += filteredSampes[samp].samples[count].opto + "," + filteredSampes[samp].samples[count].ntc + ",";                
        //     }
        //     csvContent.slice(0, -1); 
        //     csvContent += "\n";
        // }

        let encodedUri = encodeURI(csvContent);
        
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();  
      

    }

    getSerial = async ()=>{

        let _serial = await this.getSerialNumber()
        this.setState({
            serial: _serial
        })

    }

    async getSerialNumber () {
       debugger
        await this.loadDevice();

        return new Promise((resolve, reject) => {

            try {

                let serialNumberMBS = [];
                let serialNumberLBS = [];

                const serialNumberEventHandler = (event) => {        
                    debugger
                    let array = new Uint8Array(event.data.buffer);
                    console.log("<-- " + array)
                    if(serialNumberMBS.length === 0) {
                        serialNumberMBS = array.slice(4, 10);

                        // LBS
                        const cmd = new Uint8Array([85,69,2,10,0,0,0,0,0,0,0,0,0,0,170,251,172]);
                        const buffer = crcGenerate(cmd);                
                        this.deviceSendReport(buffer);

                    } else {
                        serialNumberLBS = array.slice(4, 10);
                        const serialNumber = [...serialNumberLBS, ...serialNumberMBS];              

                        this.device.removeEventListener('inputreport', serialNumberEventHandler);

                        resolve(serialNumber.toString().replace(/,/g, ""));
                    }            
                }

                this.device.addEventListener('inputreport', serialNumberEventHandler, false);

                //mbs
                const cmd = new Uint8Array([85,69,3,10,0,0,0,0,0,0,0,0,0,0,170,251,172]);                
                const buffer = crcGenerate(cmd);                
                this.deviceSendReport(buffer);
                
            } catch (error) {
                reject(error)
            }

        })

    }

    async deviceSendReport (report) {
        console.log("--> " + report)
        if (!this.device.opened){
            await this.device.open()
        }   

        if(this.device.opened) {
            this.device.sendReport(0x00, report).then(() => {                 
                console.log('the package has been sent');
            });
        }
        
    }

    openChart = (event, date, type) => {
        
        let link = window.location.origin + "/preview?date=" + encodeURIComponent(date);
        if (type === "dp") {
            link += "&type=dp";   
        }
        window.open(link, "_blank");        
    }

    handleSampleCheckbox = (event, date) => {
        let newPrevs = [...this.state.prevs];
        for (let index = 0; index < newPrevs.length; index++) {
            const element = newPrevs[index];

            if (element.date === date) {                
                newPrevs[index].sel = !newPrevs[index].sel;             
            }             
        }
        
        this.setState({
            prevs : newPrevs
        })
    }

    render() {
        return (
            <div>
                <div className="config">
                    <div className="field">
                        <label title="Identificação da amostra" htmlFor="sampleId">
                            Identificação: <input onChange={this.handleIdChange} value={this.state.sampleId} type="text" name="" id="sampleId"/>
                        </label>
                    </div>
                    <div className="field">
                        <label title="Quantos ciclos coletados?" htmlFor="cicles">
                            Ciclos: <input onChange={this.handleCiclesChange} value={this.state.cicles} type="text" name="" id="cicles"/>
                        </label>
                    </div>

                    <div className="field">
                        <label title="Múltiplos de 100 milissegundos" htmlFor="standByTime">
                            Tempo de espera: <input onChange={this.handleStandByChange} value={this.state.standByTime} type="text" name="" id="standByTime"/>
                        </label>
                    </div>

                    <div className="field">
                        <label title="Múltiplos de 100 milissegundos" htmlFor="windowTime">
                            Janela de Atuação: <input onChange={this.handleWindowChange} value={this.state.windowTime} type="text" name="" id="windowTime"/>
                        </label>
                    </div>

                    <div className="field">
                        <label title="em milissegundos" htmlFor="sampleTime">
                        Entre amostras: <input onChange={this.handleSampleChange} value={this.state.sampleTime} type="text" name="" id="sampleTime"/>
                        </label>
                    </div>
                    

                    

                    

                    
                    
                </div>

                <div className="deviceInfo">

                    <table>
                        <tbody>
                            <tr>
                                <td>ProductId:</td><td>{this.device ? this.device.productId : '-'}</td>
                                <td>vendorId:</td><td>{this.device ? this.device.vendorId : '-'}</td>
                                <td>productName:</td><td>{this.device ? this.device.productName : '-'}</td>
                                <td>Serial:</td><td>{this.state.serial}</td>
                                
                            </tr>
                        </tbody>
                        
                    </table>
                                    
                    
                </div>

                


               <div className="comandos">
                    <button className="start" onClick={this.startHandler}>Iniciar</button> 
                    <button className="clear" onClick={this.clearStateHandler}>Limpar Memoria</button>  
                    <button className="export" onClick={this.exportHandler}>CSV</button>
                    <button className="export" onClick={this.exportJsonHandler}>JSON</button>
                    <button className="serial" onClick={this.getSerial}>Serial</button>

                    
               </div>



               <div className="Opts">
                    <label className="form-check-label">
                        <input type="checkbox"
                            checked={this.state.addLed}
                            onChange={()=> {
                                const newValue = this.state.addLed ? false : true;
                                this.setState({
                                    addLed : newValue
                                })}
                            }
                            className="form-check-input"
                        />
                        Led
                    </label>
                    <label>
                        <input type="checkbox"
                            checked={this.state.addTemp}
                            onChange={()=> {
                                const newValue = this.state.addTemp ? false : true;
                                this.setState({
                                    addTemp : newValue
                                })}
                            }
                            className="form-check-input"
                        />
                        Temperatura
                    </label>
                    <label>
                        <input type="checkbox"
                            checked={this.state.addHomo}
                            onChange={()=> {
                                const newValue = this.state.addHomo ? false : true;
                                this.setState({
                                    addHomo : newValue
                                })}
                            }
                            className="form-check-input"
                        />
                        Homog.
                    </label>

                    
               </div>


                <p>Status: {this.state.status}</p>


                  


                <div className="historico"> 
                    <table>
                        <thead>
                            <tr>
                                <td>Selecionar</td>
                                <td>ID</td>
                                <td>Data</td>
                                <td>Ciclos</td>
                                <td>Tempo (segundos)</td>
                                <td>Counts</td> 
                                <td>Check</td>
                                <td>DP</td>
                                <td>Preview</td>                                
                                
                            </tr>
                        </thead>

                        <tbody>

                            {this.state.prevs.map((prev, i) => {     
                                if (!prev.date) {
                                    return (null);                                   
                                }              
                                
                                return (
                                    <tr key={prev.date}>
                                        <td>{prev.sel} 
                                        <input
                                            name="isSelected"
                                            type="checkbox"
                                            checked={prev.sel}
                                            onChange={(event)=>{this.handleSampleCheckbox(event, prev.date)}} />
                                        
                                        </td>
                                        <td>{prev.sampleId} </td>
                                        <td>{prev.date} </td>
                                        <td>{prev.ciclos} </td>
                                        <td>{prev.time} </td>
                                        <td>{prev.counts} </td> 
                                        <td className={prev.check ? "green" : "red"}>{prev.check ? "OK" : "NOK"} </td>                                        
                                        
                                        <td><button onClick={(event)=>this.openChart(event, prev.date, "dp")}>Abrir</button></td>
                                        <td><button onClick={(event)=>this.openChart(event, prev.date)}>Abrir</button></td>
                                        
                                    </tr>
                                );
                            })}


                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

export default Communicat;