import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import Chart from "react-google-charts";
import SamplePattern from "../shared/pattern";

const Preview = props => {
    
    const location = useLocation();
    
    const parsedQuery = queryString.parse(location.search);

    
    const [chartData, setChartData] = useState([]);
    

    useEffect(() => {
        console.log(SamplePattern.length);
        if (localStorage.getItem("coletas") !== null) { 
        
            let data = []
            try{
                debugger
                const coletas = JSON.parse(localStorage.getItem("coletas"));
                const coleta = coletas.find(el => el.date === parsedQuery.date);

                if(parsedQuery.type === "dp") {
                    data = [['x', 'DP']]

                    for (let y = 0; y < coleta.samples.length; y++) {
                        data.push([y, coleta.DP[y]])
                    }
                    
                } else {
                    data = [['x', 'opto', 'ref']]
                    for (let i = 0; i < coleta.samples.length; i++) {
                        const element = coleta.samples[i];
                        data.push([i, element.opto, SamplePattern[i]])
                        
                    }
                }
    
                
                
            } catch(err){
                console.log(err);
            }
            setChartData(data);
            
        }         
    
        
    }, [ parsedQuery.date, parsedQuery.type] );

    if(chartData.length > 0) {
        return(       
            <Chart
                width={'100%'}
                height={'99vh'}
                chartType="LineChart"
                loader={<div>Carregando grafico</div>}
                data={chartData}                    
                options={{
                    intervals: { style: 'sticks' },
                    legend: 'none',
                    colors: ['blue','gray'],
                    chartArea: {                       
                        left:70,
                        top:20,
                        right:20,
                        bottom:40,
                        width:"100%",
                        height:"100%",
                    }
                  }} 
                  

                
            />
        )
    } else {
        return(<p>Carregando Dados</p>)
    }
    
}

export default Preview;

